import { defineStore } from 'pinia';

import { AydenPayResultDataType } from '../types';

export interface PaymentState {
  loadingPaymentMethod: boolean,
  paymentDetails: object,
  loading: string,
  error: {
    message?: string,
    [key:string]: any,
  } | null,
  selectedError: object,
  payflowError: object,
  payflowExpress: object,
  adyenPaymentStatus: AydenPayResultDataType | {},
  adyenPaymentDetails: AydenPayResultDataType | {};
  adyenComponent: object | null;
}

export interface CheckoutState {
  [key: string]: any;
}

export declare type PayloadAction<P = void, T extends string = string, M = never, E = never> = {
  payload: P;
  type: T;
} & ([M] extends [never] ? {} : {
  meta: M;
}) & ([E] extends [never] ? {} : {
  error: E;
});

export const usePaymentStore = defineStore('payment', {
  state: (): PaymentState => ({
    loadingPaymentMethod: false,
    paymentDetails: {},
    loading: '',
    error: null,
    adyenComponent: null,
    selectedError: {},
    payflowError: {},
    payflowExpress: {},
    adyenPaymentStatus: {},
    adyenPaymentDetails: {},
  }),
  actions: {
    addSelectedPayment(payload) {
      this.paymentDetails = payload;
      console.log('_state.paymentDetails', this.paymentDetails);

      this.loadingPaymentMethod = true;
    },
    addPaymentDetails(payload) {
      this.adyenPaymentDetails = payload;
      this.loading = 'loaded';
      this.error = null;
    },
    addPaymentDetailsError(payload) {
      this.error = payload;
      this.loading = 'canceled';
    },
    addAdyenPaymentStatus(payload) {
      this.adyenPaymentStatus = payload;
      this.loading = '';
      this.error = null;
    },
    initAdyenPayment(action: Boolean) {
      if (action) this.loading = 'loading';
    },
    clearAdyenPayment(action) {
      if (action.includes('CLEAR_STATE')) {
        this.adyenPaymentStatus = {};
        this.adyenPaymentDetails = {};
        this.error = null;
      }
    },
    setAdyenComponent(payload) {
      this.adyenComponent = payload;
    }
  },
});
