
























































import '@adyen/adyen-web/dist/adyen.css';

import AdyenCheckout from '@adyen/adyen-web';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useContext,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import ErrorBar from '~/components/ErrorBar.vue';
import { useApi, useCart, useUiNotification } from '~/composables';
import { usePaymentStore } from '~/modules/checkout/stores/payment';

import {
  GetAdyenPayDetail,
  PlaceOrderGql,
} from '../composables/useMakeOrder/commands/adyenPay.gql';
import { AdyenOrder } from '../types';

export default defineComponent({
  name: 'AydenPayment',
  components: {
    ErrorBar,
  },
  emits: ['finalplace'],
  setup(_props, { emit }) {
    const { query } = useApi();
    const { cart, load: loadCart, setCart } = useCart();

    const aydenRef = ref(null); // component for mount
    const router = useRouter();
    const { send: sendNotification } = useUiNotification();
    const isLoading = ref<Boolean>(false);
    const {
      app: {
        $vsf,
        localeRoute,
        i18n: { locale },
      },
      $config: { adyenPayClientID },
    } = useContext();
    const order = ref<AdyenOrder>();
    const cardState = ref<any>();
    const valid = ref<Boolean>(false); // check if card is valid
    const paymentStore = usePaymentStore();
    const userEmail = computed(() => cart.value.email ?? '');
    const cartId = computed(() => cart.value.id ?? '');
    const {
      adyenPaymentDetails,
      loading: isPaying,
      error: paymentError,
    } = storeToRefs(paymentStore);
    const cardComponent = ref();

    // ________________________________________________
    // eslint-disable-next-line @typescript-eslint/require-await
    const handleServerResponse = async (res, component) => {
      if (res.action) {
        console.log('*** Got Res Action ***:', JSON.parse(res.action));
        component.handleAction(JSON.parse(res.action));
      } else if (
        res.isFinal &&
        res.resultCode === ('Authorised' || 'Received' || 'PresentToShopper')
      ) {
        sendNotification({
          id: Symbol('adyen pay success'),
          message: 'Congratualations! You have paid it successfully!',
          persist: false,
          type: 'success',
          icon: 'check',
          title: 'Success!',
        });
        // setCart(null);
        // $vsf.$magento.config.state.removeCartId();
        // await loadCart();
        router.push(
          localeRoute({
            name: 'thank-you',
            query: {
              order: order.value.order_number,
            },
          })
        );
      }
    };
    const handleOnChange = (state: any, component: any) => {
      console.log('**** Adyen handleOnChange state:', state);
      valid.value = state.isValid;
      cardState.value = state.data;
      cardComponent.value = component;
    };

    const handlePlaceOrder = async (
      paymentMethod: string,
      cardDetails?: any
    ) => {
      if (paymentMethod) {
        isLoading.value = true;
        paymentStore.clearAdyenPayment('CLEAR_STATE');
        const path = '/thank-you';
        const stateData = JSON.parse(cardDetails);
        const ccType = stateData.paymentMethod.brand;
        const paramsAdyen = {
          cart_id: cartId.value,
          cc_type: ccType,
          guestEmail: userEmail.value,
          code: paymentMethod,
          return_url: `https://${window.location.hostname}${path}`,
          stateData: cardDetails,
        };
        const { data, errors } = await query<any>(PlaceOrderGql, paramsAdyen);
        console.log('!!!!Adyen Pay Result:', data, errors);
        isLoading.value = false;
        if (errors && errors.length > 0) {
          console.log('** Adyen Place order error:', errors[0]);
          paymentStore.addPaymentDetailsError(errors[0]);
          sendNotification({
            id: Symbol('place_adyen_order_error'),
            message: errors[0].message as string,
            persist: false,
            type: 'danger',
            icon: 'check',
            title: 'Error!',
          });
          return;
        }
        // ---- **** success **** -----
        const { placeOrder } = data;
        order.value = placeOrder.order;
        paymentStore.addSelectedPayment(paymentMethod); // ayden_cc
        paymentStore.initAdyenPayment(true); // let state.loading = true;
        paymentStore.addPaymentDetails(order.value.adyen_payment_status);
      } else {
        sendNotification({
          id: Symbol('np_adyen_method_error'),
          message:
            'The payment method is missing. Select the payment method and try again.',
          persist: false,
          type: 'danger',
          icon: 'check',
          title: 'Error!',
        });
      }
    };

    // const handleCardSubmit = () => {
    //   if (valid.value) {
    //     handlePlaceOrder('adyen_cc', JSON.stringify(cardState.value));
    //   } else {
    //     sendNotification({
    //       id: Symbol('adyen_submit_error'),
    //       message: 'Sorry, card informations are not valid.',
    //       persist: false,
    //       type: 'danger',
    //       icon: 'check',
    //       title: 'Error!',
    //     });
    //   }
    // };

    const onSubmit = (state: any) => {
      console.log('**** Adyen onSubmit state:', state);
      valid.value = state.isValid;
      cardState.value = state.data;
      // google-analytic: add_payment_info
      emit('finalplace', 'adyen_cc');
      handlePlaceOrder('adyen_cc', JSON.stringify(state.data));
    };

    const handleAdditionalDetails = async (state: any, component: any) => {
      // -- update payment action --
      console.log('_handleAdditionalDetails', state.data);
      cardComponent.value = component;
      const paramData = state.data;
      const request = { ...paramData, orderId: order.value?.order_number };
      const param = {
        payload: JSON.stringify(request),
        cartId: cartId.value,
      };
      isLoading.value = true;
      const {
        data: { adyenPaymentDetails: adyenPaymentDetailsRes },
        errors,
      } = await query<any>(GetAdyenPayDetail, param);
      isLoading.value = false;
      if (errors && errors.length > 0) {
        console.log('** Get Adyen Pay Detail error:', errors[0]);
        paymentStore.addPaymentDetailsError(errors[0]);
        sendNotification({
          id: Symbol('adyen_payment_detail_error'),
          message: errors[0].message as string,
          persist: false,
          type: 'danger',
          icon: 'check',
          title: 'Error!',
        });
        return;
      }
      console.log(
        '*** handleAdditionalDetails result:',
        adyenPaymentDetailsRes
      );
      paymentStore.addPaymentDetails(adyenPaymentDetailsRes);
    };

    const initCheckout = async () => {
      console.log('***** init adyen pay ******');
      try {
        const configuration: any = {
          locale: locale === 'cn' ? 'zh-CN' : 'en-US',
          environment: 'live',
          showPayButton: true,
          // clientKey: 'test_D3TRD24UIZGTHNFR2BQI5JFY744IMZMT',
          clientKey: adyenPayClientID,
          analytics: {
            enabled: true, // Set to false to not send analytics data to Adyen.
          },
          onAdditionalDetails: handleAdditionalDetails, // Pass the updated callback function here
          onChange: handleOnChange,
          onSubmit,
          onError: (error, component) => {
            console.error(error.name, error.message, component);
            sendNotification({
              id: Symbol('adyen_payment_error'),
              message: error.message as string,
              persist: false,
              type: 'danger',
              icon: 'check',
              title: error.name,
            });
            paymentStore.addPaymentDetailsError(error);
          },
          onPaymentCompleted: (result, component) => {
            console.log(`** [onPaymentCompleted result: ${result}`);
            handleServerResponse(result, component);
          },
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              billingAddressRequired: false,
              name: 'Credit or debit card',
              brands: ['visa', 'amex', 'diners', 'discover', 'maestro', 'mc'],
              details: [
                { key: 'holderName', type: 'text' },
                { key: 'number', type: 'text' },
                { key: 'expiryMonth', type: 'text' },
                { key: 'expiryYear', type: 'text' },
                { key: 'cvc', type: 'text' },
              ],
              validationRules: {},
            },
          },
        };
        const checkout = await AdyenCheckout(configuration);
        const cardComponent: any = checkout
          .create('card')
          .mount(aydenRef.value);
        console.log('**** Create card component', cardComponent);
        cardComponent.value = cardComponent;
      } catch (error) {
        sendNotification({
          id: Symbol('adyen_payment_catch_error'),
          message: error.message as string,
          persist: false,
          type: 'danger',
          icon: 'check',
          title: 'Invalid data',
        });
        console.error('Invalid data for payments', error);
      }
    };

    watch(adyenPaymentDetails, (newObj) => {
      if (order.value && order.value.order_number) {
        handleServerResponse(newObj, cardComponent.value);
      }
    });
    onMounted(async () => {
      // todo
      await initCheckout();
    });

    onUnmounted(() => {
      // todo
      paymentStore.addPaymentDetailsError(null);
      paymentStore.clearAdyenPayment('CLEAR_STATE');
    });
    return {
      // ...
      aydenRef,
      isLoading,
      adyenPaymentDetails,
      paymentError,
      isPaying,
      // handleCardSubmit,
    };
  },
});
