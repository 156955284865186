var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"adyen"}},[_c('div',{ref:"aydenRef"}),_vm._v(" "),_c('div',{staticClass:"adyen-action"},[_c('div',{staticClass:"adyen-action_tip"},[(
          _vm.adyenPaymentDetails &&
          'resultCode' in _vm.adyenPaymentDetails &&
          _vm.adyenPaymentDetails.resultCode === ('Refused' || 'Error')
        )?_c('div',[_c('ErrorBar',{attrs:{"text":("Error Code: " + (_vm.adyenPaymentDetails.resultCode)),"tip-type":"error"}})],1):(
          _vm.adyenPaymentDetails &&
          'resultCode' in _vm.adyenPaymentDetails &&
          _vm.adyenPaymentDetails.resultCode ===
            ('Authorised' || 'Received' || 'PresentToShopper')
        )?_c('div',[_c('ErrorBar',{attrs:{"text":_vm.$t('paymentSuccessful'),"tip-type":"success"}})],1):_vm._e(),_vm._v(" "),(_vm.paymentError && _vm.paymentError.message)?_c('div',[_c('ErrorBar',{attrs:{"text":("[" + (_vm.$t('paymentRefused')) + "] " + (_vm.paymentError.message)),"tip-type":"error"}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }