export const PlaceOrderGql = `
mutation PlaceOrder(
    $cart_id: String!
    $code: String!
    $cc_type: String
    $return_url: String
    $guestEmail: String
    $stateData: String
  ) {
    setPaymentMethodOnCart(
      input: {
        cart_id: $cart_id
        payment_method: {
          code: $code
          adyen_additional_data_cc: {
            cc_type: $cc_type
            recurringProcessingModel: "Subscription"
            guestEmail: $guestEmail
            returnUrl: $return_url
            stateData: $stateData
          }
        }
      }
    ) {
      cart {
        selected_payment_method {
          code
          title
        }
      }
    }
    placeOrder(input: { cart_id: $cart_id }) {
      order {
        order_number
        cart_id
        adyen_payment_status {
          isFinal
          resultCode
          additionalData
          action
        }
      }
    }
  }
`;

export const GetAdyenPayDetail = `
mutation getAdyenPaymentDetails($payload: String!, $cartId: String!) {
  adyenPaymentDetails(payload: $payload, cart_id: $cartId) {
    isFinal
    resultCode
    additionalData
    action
  }
}
`;

export const GetAdyenPayStatus = `
query getAdyenPaymentStatus($orderNumber: String!, $cartId: String!) {
  adyenPaymentStatus(orderNumber: $orderNumber, cartId: $cartId) {
    isFinal
    resultCode
    additionalData
    action
  }
}
`;
