
















import { defineComponent } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';

export default defineComponent({
  nama: 'ErrorBar',
  components: {
    SfIcon,
  },
  props: {
    tipType: {
      default: 'error',
      type: String
    },
    text: {
      default: '',
      type: String,
    }
  },
  computed: {
    iconConfig() {
      if (this.tipType === 'error') {
        return {
          name: 'error',
          color: 'red-primary'
        };
      } if (this.tipType === 'success') {
        return {
          name: 'info',
          color: 'green-primary'
        };
      }
      return {
        name: 'info',
        color: 'yellow-primary'
      };
    },
    backBarColor() {
      if (this.tipType === 'success') {
        return 'green';
      } if (this.tipType === 'error') {
        return 'red';
      }
      return 'lightcoral';
    }
  }
});
